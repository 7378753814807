/* eslint-disable import/prefer-default-export */

import Vue from 'vue';
import VueRouter from 'vue-router';
import moment from 'moment';
import CookieManager from '@/core/utils/cookieManager';

import { API } from '@/api/core';
import Config from '@/config';

Vue.use(VueRouter);

const routeGuard = (to, from, next, bootInstance, store, reject) => {
  const activeUserKey = bootInstance && bootInstance.user && bootInstance.user.user;
  const userRequired = to.matched.some((record) => record.meta.userRequired);
  const loginRequired = userRequired && (!activeUserKey);
  let nextHandled = false;
  if (loginRequired) {
    console.log('Login Required', from.fullPath, to.fullPath, loginRequired, API.token);
    nextHandled = true;
    const useRegister = to.matched.some((record) => record.meta.useRegister);
    const nextPath = encodeURI(to.fullPath);
    const params = { name: (useRegister) ? 'signUp' : 'login', query: { next: nextPath } };
    if (Config.isClientEnv) {
      // Route Redirection before mount not working. So
      // doing lazy redirection
      if (!store.state.boot.appMounted) {
        store.commit('boot/updateLoginRequired', { params });
        next();
      } else {
        next(params);
      }
    } else {
      const redirectParam = {
        path: (useRegister) ? '/user/register/' : '/user/login/',
        query: { next: nextPath },
      };
      reject({ code: 302, params: redirectParam });
    }
  }

  if ((to.path === '/user/project/create' || to.path === '/user/project/create/')
    && (!to.query.license && !to.query.package)) {
    console.log('redirect');
    window.location.href = '/v5/user/project/review';
  }

  if (!nextHandled) {
    next();
  }
};

export const createRouter = (apiInstance, apicontext, reject, store) => {
  const routes = [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "v5web" */ '../views/RZWebsite/Base.vue'),
      meta: { baseRouteName: 'rsCricketHome', pageType: 'rsCricketHome' },
      children: [
        {
          path: '',
          name: 'rsCricketHome',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/Home.vue'),
          meta: { routeValue: 'cricket-home' },
        },
        {
          path: '/get-started/',
          name: 'rsGetStarted',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/GetStarted.vue'),
          meta: { routeValue: 'get-started' },
        },
        {
          path: '/resource/:api/',
          name: 'rsResourcePage',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/Resource.vue'),
          props: (route) => ({ resourceType: route.params.api }),
        },
        {
          path: '/package/:api/',
          name: 'rsPackageResourcePage',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/PackageResource.vue'),
          props: (route) => ({ resourceType: route.params.api }),
        },
        {
          path: '/solution/:api/',
          name: 'rsSolutionPage',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/Solution.vue'),
          props: (route) => ({ solutionType: route.params.api }),
        },
        {
          path: '/coverage/',
          name: 'rsCoverage',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/Coverage.vue'),
          meta: { routeValue: 'coverage' },
        },
        {
          path: '/fantasy-points-system/',
          name: 'rsFantasyPointsSystem',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/FantasyPointsSystem.vue'),
          meta: { routeValue: 'fantasy-points-system' },
        },
        {
          path: '/pricing/',
          name: 'rsPricing',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/Pricing.vue'),
          meta: { routeValue: 'pricing' },
        },
        {
          path: '/package-pricing/',
          name: 'rsPackagePricing',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/PackagePricing.vue'),
          meta: { routeValue: 'package-pricing' },
        },
        {
          path: '/plans/',
          name: 'rsPlans',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/License.vue'),
          meta: { routeValue: 'plans' },
        },
        {
          path: '/about-us/',
          name: 'rsAboutUs',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/InfoPage.vue'),
          meta: { routeValue: 'about-us' },
        },
        {
          path: '/contact-us/',
          name: 'rsContactUs',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/InfoPage.vue'),
          meta: { routeValue: 'contact-us' },
        },
        {
          path: '/committed-use/',
          name: 'rsCommittedUse',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/InfoPage.vue'),
          meta: { routeValue: 'committed-use' },
        },
        {
          path: '/blog/archives/',
          name: 'rsBlogArchives',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/BlogArchives.vue'),
          meta: { routeValue: 'rs-cricket-blog-archive' },
        },
        {
          path: '/blog/:blogid/',
          name: 'rsBlogDetailView',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/BlogDetailView.vue'),
          props: (route) => ({ blogId: route.params.blogid }),
        },
        {
          path: '/blogs/',
          name: 'rsBlogList',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/BlogList.vue'),
          meta: { routeValue: 'rs-cricket-blog-detail' },
        },
        {
          path: '/:projectKey/article/archives/',
          name: 'rsArticleArchives',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/ArticleArchives.vue'),
          meta: { routeValue: 'rs-cricket-article-archive' },
          props: (route) => ({ projKey: route.params.projectKey }),
        },
        {
          path: '/:projectKey/article/:articleid/',
          name: 'rsArticleDetailView',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/ArticleDetailView.vue'),
          props: (route) => ({
            articleId: route.params.articleid,
            projKey: route.params.projectKey,
          }),
        },
        {
          path: '/:projectKey/articles/',
          name: 'rsArticleList',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/ArticleList.vue'),
          meta: { routeValue: 'rs-cricket-article-detail' },
          props: (route) => ({ projKey: route.params.projectKey }),
        },
        {
          path: '/contact-sales/',
          name: 'rsContactSales',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/ContactSales.vue'),
          meta: { routeValue: 'contact-sales' },
        },
        {
          path: '/graphql/',
          name: 'rsGraphQL',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/GraphQL.vue'),
          meta: { routeValue: 'graphQL' },
        },
        {
          path: '/graphql/pricing/',
          name: 'rsGraphQLPricing',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/GraphQLPricing.vue'),
          meta: { routeValue: 'pricing-graphQL' },
        },
        {
          path: '/graphql/resource/:query/',
          name: 'rsGraphQLResourcePage',
          component: () => import(/* webpackChunkName: "v5web" */ '../views/RsCricket/GraphQLResource.vue'),
          props: (route) => ({ resourceType: route.params.query }),
        },
      ],
    },
    {
      path: '/user/',
      name: 'userViews',
      component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/Base.vue'),
      children: [
        {
          path: 'login/',
          name: 'login',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/LoginPage.vue'),
          meta: { routeValue: 'login' },
        },
        {
          path: 'login-response/',
          name: 'LoginResponse',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/LoginResponse.vue'),
        },
        {
          path: 'register/',
          name: 'signUp',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/Signup.vue'),
          meta: { routeValue: 'signup' },
        },
        {
          path: 'links/',
          name: 'userLinks',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/Links.vue'),
        },
        {
          path: 'me/',
          name: 'userMe',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/Me.vue'),
          meta: { userRequired: true },
        },
        {
          path: 'payments/',
          name: 'userTestPayments',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/TestPayments.vue'),
          meta: { userRequired: true },
        },
        {
          path: 'forgot-password/',
          name: 'forgotPassword',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/ForgotPassword.vue'),
        },
        {
          path: 'project/review/',
          name: 'planReview',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/payments/ReviewProject.vue'),
          meta: {
            tracking: {
              path: '/user/project/create',
            },
          },
        },
        {
          path: 'project/create/',
          name: 'projectCreation',
          // component: () => import('../views/user/CreateProject.vue'),
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/payments/CreateProjectWizard.vue'),
          meta: {
            userRequired: true,
            useRegister: true,
            tracking: {
              path: '/user/project/PK/wizard',
              appendQS: ['step'],
              qsDefaults: { step: 'project_name' },
            },
          },
        },
        {
          path: 'project/:projectKey/wizard/',
          name: 'projectWizard',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/payments/PaymentWizard.vue'),
          meta: {
            userRequired: true,
            tracking: {
              path: '/user/project/PK/wizard',
              appendQS: ['step'],
              qsDefaults: { step: 'commited_use' },
            },
          },
        },
      ],
    },
    {
      path: '/docs/',
      component: () => import(/* webpackChunkName: "v5docs" */ '../views/docs/Base.vue'),

      // By design, Router expects no name for
      // base path and expects empty path on child to
      // have index page. But it messes with active Class.
      // Following property introduce to deal with this bug
      meta: { sportValue: 'cricket', baseRouteName: 'docsHome' },

      children: [
        {
          path: '',
          name: 'docsHome',
          component: () => import(/* webpackChunkName: "v5docs" */ '../views/docs/WebPage.vue'),
          meta: { sportValue: 'cricket', routeValue: 'docs-home' },
        },
        {
          path: ':page/',
          name: 'docsAPI',
          component: () => import(/* webpackChunkName: "v5docs" */ '../views/docs/API.vue'),
          meta: { sportValue: 'cricket', routeValue: 'docs-home' },
        },
        {
          path: 'guides/:page/',
          name: 'docsGuide',
          component: () => import(/* webpackChunkName: "v5docs" */ '../views/docs/WebPage.vue'),
          meta: { sportValue: 'cricket', routeValue: 'docs-home' },
        },
      ],
    },
    {
      path: '/graphql/docs/',
      component: () => import(/* webpackChunkName: "v5docs" */ '../views/graphQL/Base.vue'),
      meta: { sportValue: 'cricket', baseRouteName: 'graphQLHome' },

      children: [
        {
          path: '',
          name: 'graphQLHome',
          component: () => import(/* webpackChunkName: "v5docs" */ '../views/graphQL/Webpage.vue'),
          meta: { sportValue: 'cricket', routeValue: 'graphQL' },
        },
        {
          path: ':page/',
          name: 'graphQLAPI',
          component: () => import(/* webpackChunkName: "v5docs" */ '../views/graphQL/API.vue'),
          meta: { sportValue: 'cricket', routeValue: 'graphQL' },
        },
        {
          path: 'guides/:page/',
          name: 'graphQLGuide',
          component: () => import(/* webpackChunkName: "v5docs" */ '../views/graphQL/Webpage.vue'),
          meta: { sportValue: 'cricket', routeValue: 'graphQL' },
        },
      ],
    },
    {
      path: '/pages/',
      component: () => import(/* webpackChunkName: "v5docs" */ '../views/docs/Base.vue'),

      meta: { baseRouteName: 'docsPagesHome' },

      children: [
        {
          path: '',
          name: 'docsPagesHome',
          component: () => import(/* webpackChunkName: "v5docs" */ '../views/notReady.vue'),
        },
        {
          path: ':page/',
          name: 'docsPage',
          component: () => import(/* webpackChunkName: "v5docs" */ '../views/docs/WebPage.vue'),
          meta: { sportValue: 'cricket' },
        },
      ],
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "common"  */ '../views/http404.vue'),
    },
  ];

  const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_PUBLIC_PATH,
    routes,
    scrollBehavior(to) {
      if (to.hash) {
        return {
          selector: to.hash,
        };
      }

      return { x: 0, y: 0 };
    },
  });

  router.beforeEach((to, from, next) => {
    const bootInstance = (store.state.boot && store.state.boot.instance);
    if (!bootInstance) {
      store.dispatch('boot/getInstance').then(() => {
        routeGuard(to, from, next, store.state.boot.instance, store, reject);
        if (store.state.boot && store.state.boot.instance
          && store.state.boot.instance.rz_currency !== null) {
          const expires = moment().add(365, 'day');
          CookieManager.setItem('rzCurrency', (store.state.boot && store.state.boot.instance
            && store.state.boot.instance.rz_currency), expires.toDate(), '/');
        }
      }, (error) => {
        console.log('Error loading boot loader', error);
      });
    } else {
      routeGuard(to, from, next, bootInstance, store, reject);
      if (store.state.boot && store.state.boot.instance
        && store.state.boot.instance.rz_currency !== null) {
        const expires = moment().add(365, 'day');
        CookieManager.setItem('rzCurrency', (store.state.boot && store.state.boot.instance
          && store.state.boot.instance.rz_currency), expires.toDate(), '/');
      }
    }
    if ((to.path === '/user/project/create' || to.path === '/user/project/create/')
      && (!to.query.license && !to.query.package)) {
      console.log('redirect');
      router.push({ name: 'planReview' });
    }
  });

  router.afterEach((to) => {
    let gtDataLayer = [];
    if (Config.isClientEnv) {
      gtDataLayer = window.dataLayer;
    }

    const trackConfig = to.meta.tracking || {
      qsDefaults: {},
    };

    const title = trackConfig.title || null;
    let toPath = trackConfig.path || to.path;
    let publicPath = Config.rzPublicPath;
    if (toPath[toPath.length - 1] !== '/') {
      toPath = `${toPath}/`;
    }
    if (publicPath[publicPath.length - 1] === '/') {
      publicPath = publicPath.replace(/\/$/, '');
    }
    toPath = `${publicPath}${toPath}`;
    if (trackConfig.appendQS) {
      const suffix = trackConfig.appendQS.map((q) => `${q}/${to.query[q] || trackConfig.qsDefaults[q] || '-'}`);
      if (suffix.length) {
        toPath = `${toPath}${suffix.join('/')}/`;
      }
    }


    gtDataLayer.push({
      event: 'rz.pageview',
      rzpage: { path: toPath, title },
    });
  });

  return router;
};
